import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./2-YourDetails.module.css";
import classes2 from "../components/yourdetails/CalculateButton.module.css";

function ConsentPage() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const navigateToInputForm = () => {
    navigate("/yourdetails");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <h2>This is only an estimate</h2>
      <p>
        Welcome to the Police (Scotland) Pensions benefit estimate tool. It can
        give you an idea of what your pension benefits may look like when you
        choose to retire, and how they might change depending on different
        factors, like your retirement age, amount of lump sum you want to take,
        and the Public Service Pensions Remedy if you’re affected.
      </p>
      <p>
        The figures shown are an estimate only, and not your final retirement
        benefit offer. This isn’t financial advice – if you think you need
        advice, please contact an independent financial adviser. You can find
        one at unbiased.co.uk
      </p>
      <p>The assumptions we have used in this tool are:</p>
      <table>
        <tbody>
          <tr>
            <td>Future CPI Inflation</td>
            <td>2% per year</td>
          </tr>
        </tbody>
      </table>
      <p style={{ fontWeight: "bold" }}>
        We do not accept any liability for actions taken by members as a result
        of these illustrations.
      </p>
      <input
        type="checkbox"
        id="consent-checkbox"
        name="consent-checkbox"
        onChange={handleCheckboxChange}
      />
      <label htmlFor="consent-checkbox">
        I acknowledge that I have read, agree and understand the terms of
        service
      </label>
      <br />

      <div className={classes.button_container}>
        <button
          className={classes2.pagebutton}
          onClick={navigateToInputForm}
          disabled={!isChecked}
        >
          Continue to the Pension Illustrator Tool
        </button>
      </div>
    </div>
  );
}

export default ConsentPage;
