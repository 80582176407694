
import React, { Fragment, useState, useEffect, useRef } from "react";
import InfoModal from "../../general/InfoModal";
import { submitInputData } from "../../../shared/apis/mainAPI";
import {textToPercent, processPercentDelete, sanitisePercentString} from '../../../utilities/PercentageHandlingFunctions'

import classes from "./ControlSwitches.module.css";

const LumpSumSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [commutation, setCommutation] = useState(props.inputsRef.current.commutation)
  const [equaliseLumpSum, setEqualiseLumpSum] = useState(props.inputsRef.current.equalise)
  const [text_focused, set_text_focused] = useState(false)
  const [legacyScheme] = useState(props.inputsRef.current.legacyScheme)
  const min_commutation = 0
  const max_commutation = 100
  const [commutation1987, setCommutation1987] = useState(props.inputsRef.current.commutation1987)
  // State to track which modal to show, null when no modal is displayed
  const [activeModal, setActiveModal] = useState(null);

  function changeHandler(event) {
    // Retrieve the ID from the input element to determine which state to update
    const sliderId = event.target.id;
    
    // Use the ID to decide which commutation value to process
    const commutationValue = sliderId === 'commutation1987' ? commutation1987 : commutation;
  
    const old_val = textToPercent(commutationValue); // Adjusted to use the appropriate commutation value
    var new_val = processPercentDelete(event.target.value, old_val).replace("%", "");
    new_val = sanitisePercentString(new_val);
  
    const ends_with_decimal = new_val.charAt(new_val.length - 1) === '.';
    new_val = Math.min(Number(new_val / 100), max_commutation / 100);
    new_val = Math.max(new_val, min_commutation / 100);
  
    const finalValue = String(Math.round(new_val * 1000) / 10) + (ends_with_decimal ? '.' : '');
  
    // Update the appropriate state based on the slider ID
    if (sliderId === 'commutation1987') {
      setCommutation1987(finalValue);
    } else if (sliderId === 'commutation') {
      setCommutation(finalValue);
    }
  }

  const [mouseDown, setMouseDown] = useState(false)
  const initial_mount = useRef(true)

  useEffect(() => {
    if ((!initial_mount.current) && !mouseDown &&!text_focused) {
      
  
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current['commutation'] = commutation;
        props.inputsRef.current['equalise'] = equaliseLumpSum;
        props.inputsRef.current['commutation1987'] = commutation1987;
        try {
          const data = await submitInputData(
            {...props.inputsRef.current, 'commutation2015': commutation, 'commutation1987': commutation1987, 'equalise': equaliseLumpSum}
          );
          props.setOutputData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle any errors here
        }
      };
  
      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [commutation, commutation1987, equaliseLumpSum, mouseDown, text_focused]);

  const sliderHandler = (event) => {
    setMouseDown(true);
    event.target.id === "commutation" ? setCommutation(event.target.value) : setCommutation1987(event.target.value);
  };

  const modalHandler = (modalId) => {
    // If the current modal is already open, close it, otherwise open the specified one
    setActiveModal(activeModal === modalId ? null : modalId);
  }

  const infoMessageSwitch = (
    <Fragment>
      <h2>Equalise Lump Sums</h2>
      <p>
      When the switch is turned on, it is assumed that no commutation is taken in 
      the legacy scenario and that the commutation amount taken in reformed 
      scheme scenario is such that total lump sums are equal in both scenarios.
      </p>
    </Fragment>
  );

  const infoMessageSlider2015 = (
    <Fragment>
      <h2>Cash lump sum in 2015 scheme</h2>
      <p>
      Members are generally able to exchange (commute) up to 35.7% of their 2015 
      Scheme pension for a cash lump sum (limits set by HMRC). Setting this slider 
      to “100” will therefore illustrate the lump sum and reduced pension you 
      will receive after commuting 35.7% of your 2015 Scheme pension.
      </p>
    </Fragment>
  )

  const infoMessageSlider1987 = (
    <Fragment>
      <h2>Cash lump sum in 1987 scheme</h2>
      <p>
      Members are able to exchange (commute) some of their pension for a cash lump 
      sum. The amount of pension that can be commuted in the legacy Scheme is subject to 
      the limits set out in the Regulations. Please refer to the Regulations for full details
      of the commutation limits. This illustrator applies the limits described above.
      </p>
      <p>
        Please note that restrictions on pension commutation are also imposed by the pension
        taxation regime under Finance Act 2004. The main taxation restriction is that for a lumo
        sum to be authorised under the tax rules it must not exceed 25% of the total value of
        benefits crystallised. Please refer to HMRC guidance for further information. 
      </p>
    </Fragment>
  )

  return (
    <div className={classes.main_container + ' '+ classes.els + ' ' + classes.lump_sum} 
    style = {{'--els-height' : equaliseLumpSum === 'equal' ? '0px' : '12vh'}}
    >
      {activeModal === "switchHelp" && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessageSwitch} />
      )}
      {activeModal === "slider2015Help" && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessageSlider2015} />
      )}
      {activeModal === "slider1987Help" && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessageSlider1987} />
      )}
      
      {legacyScheme === "2006" && 
      <Fragment>
        <div className = {classes.els_inner_container + ' ' + (equaliseLumpSum === 'equal' ? classes.adjusted : '')}>
        <header className={classes.header}>
        <h3>Equalise Lump Sums</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={() => modalHandler("switchHelp")}
        >
          Help
        </button>
      </header>
      
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioUnequal"
          name="radioEqualiseLumpSum"
          value="unequal"
          onChange={
            () => {
              setEqualiseLumpSum("unequal")
              props.inputsRef.current.equalise = 'unequal'
            }
            }
            checked={equaliseLumpSum === "unequal"}
        />
        <label htmlFor="radioUnequal">Unequal</label>
        <input
          type="radio"
          id="radioEqual"
          name="radioEqualiseLumpSum"
          value="equal"
          onChange={
            () => {
              setEqualiseLumpSum("equal")
              props.inputsRef.current.equalise = 'equal'
            }
            }
            checked={equaliseLumpSum === "equal"}
        />
        <label htmlFor="radioEqual">Equal</label>
      </div>
      </div>
    </Fragment>}
      
      {legacyScheme === "1987" &&
      <Fragment>
        <div className = {classes.els_inner_container}>
        <header className={classes.header}>
          <h3>Cash lump sum in 1987 scheme</h3>
          <button
          id="LumpSumSlider1987Help"
          className={classes.help_button}
          onClick={() => modalHandler("slider1987Help")}
          >
            Help
          </button>
          </header>
        </div>
        <div className={classes.radio_container + ' ' + classes.els_slider + ' ' + (equaliseLumpSum === 'equal' ? classes.invisible : '')}>
        
          <input
            id="commutation1987"
            type="range"
            min="0"
            max="100"
            step="0.1"
            value={commutation1987}
            onChange={sliderHandler}
            className="slider"
            onMouseUp={() => {
              setMouseDown(false);
            }}
            onKeyUp={() => {
            setMouseDown(false);
            }}
          />
          <input type = 'text' id = "commutation1987" value = {commutation1987 + '%'} 
          onChange = {changeHandler}
          onFocus = {() => set_text_focused(true)}
          onBlur = {() => set_text_focused(false)}
          />
        </div>
      </Fragment>}

      <div className = {classes.els_inner_container + ' ' + (equaliseLumpSum === 'equal' ? classes.invisible : '')}>
      <header className={classes.header + ' ' + (equaliseLumpSum === 'equal' ? classes.invisible : '')}>
        <h3>Cash lump sum in 2015 scheme</h3>
        <button
        id="LumpSumSlider2015Help"
        className={classes.help_button}
        onClick={() => modalHandler("slider2015Help")}
        >
          Help
        </button>
        </header>
      </div>
      <div className={classes.radio_container + ' ' + classes.els_slider + ' ' + (equaliseLumpSum === 'equal' ? classes.invisible : '')}>
      
        <input
          id="commutation"
          type="range"
          min="0"
          max="100"
          step="0.1"
          value={commutation}
          onChange={sliderHandler}
          className="slider"
          onMouseUp={() => {
            setMouseDown(false);
          }}
          onKeyUp={() => {
           setMouseDown(false);
          }}
        />
        <input type = 'text' id ="commutation" value = {commutation + '%'} 
        onChange = {changeHandler}
        onFocus = {() => set_text_focused(true)}
        onBlur = {() => set_text_focused(false)}
        />
      </div>
    </div>
  );
};

export default LumpSumSwitch;
