import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the illustrator, you agree you have read and understood the
          Terms & Conditions set out below.
        </p>
        <h2>1. Use of this illustrator</h2>
        <p>
          This illustrator is solely for guidance purposes and provides
          estimates only. It is not intended to provide you with financial
          advice. If you require financial advice, you should contact an
          independent financial adviser. GAD do not accept any liability for
          individual financial decisions taken based on the information provided
          in this illustrator.
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          This modeller does not override the scheme rules or pension
          legislation. If there is a difference between the modeller and the
          scheme rules, the rules will apply.
        </p>
        <p>This modeller only applies to the scheme in Scotland.</p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>2.1. Detailed results</h3>
        <p>
          The results section shows a comparison of pension and lump sum
          benefits that might be received if you choose to receive legacy or
          reformed scheme benefits for the period 1 April 2015 to 31 March 2022
          (the “remedy period”). Choosing to receive legacy scheme benefits for
          service in the remedy period may increase member contributions over
          this period. Member contributions have not been included in the
          illustrations.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
          The results pension and lump sum estimates are based on the following
          key assumptions:
        </p>
        <ul>
          <li>Future CPI inflation rate of 2% per annum.</li>
          <li>
            Actual revaluation rates for Career Average Revalued Earnings (CARE)
            schemes are applied in this modeller up to and including the latest
            available rate.
          </li>
          <li>
            Future CARE revaluation rates assumed to be in line with assumed
            future CPI rate + 1.25%, at a rate of 3.25% per annum.
          </li>
          <li>
            Deferred revaluation of CARE benefits is applied at the assumed CPI
            rate of 2% per annum. Note: This assumption is used in cases where
            members can choose to retire before 55 in the legacy scheme, and
            they are assumed to defer their CARE scheme pension until age 55.
          </li>
          <li>
            Future salary increases are set to 3.25% per annum by default.
            However, salary increases can be specified by the user (between 0%
            and 6%).
          </li>
          <li>
            Past salary increases will differ depending on whether you input
            your current salary and/or your salary as at 1 April 2015.
          </li>
          <ul>
            <li>
              If users specify both a 2015 salary and a current salary, then the
              salary is assumed to increase on a straight-line basis from 1
              April 2015 to the present day.
            </li>
            <li>
              If only one salary item is provided then the past salary is
              assumed to have increased by 3.25% per annum by default from 1
              April 2015 onwards, or as specified by the user in the slider in
              the “Detailed Results” section.
            </li>
          </ul>
          <li>
            Salary increases do not allow for any future promotional salary
            increases you may receive.
          </li>
          <li>
            It is assumed that you will retire on normal terms (i.e. you are not
            retiring in ill-health).
          </li>
          <li>
            The model takes a simple approach to calculating final salary (i.e.
            last 12 months) and makes no allowance for actual final salary
            definitions in the relevant scheme.
          </li>
          <li>
            A simplified approach had been taken to determine an individual’s
            State Pension Age (SPA).
          </li>
          <li>
            It is assumed you will remain in your current rank until retirement.
          </li>
        </ul>
        <h3>2.3. Age retirement</h3>
        <p>
          You will be able to vary your retirement age in line with scheme
          regulations and between ages <b>48 – 60 only</b>.
        </p>
        <p>
          The modeller allows for retirement from active service only. If you
          have already deferred or retired, do not use this modeller as you are
          out of scope.
        </p>
        <p>
          If you are already eligible for retirement, the illustrator will offer
          you an “immediate retirement” option which can be in between integer
          ages, as well as offering retirement at every integer age up to the
          maximum retirement age of 60 that the illustrator allows.
        </p>
        <p>
          If you are not already eligible for retirement, the illustrator will
          offer you the first integer age at which you will be eligible for
          retirement.
        </p>
        <h3>2.4. Adjustments for early payment of pension</h3>
        <p>
          The amount of pension you may receive depends on when you retire. If
          you are a 1987 member, you may be eligible to retire from active
          service as follows:
        </p>
        <ul>
          <li>At any age once you reach 30 years’ service.</li>
          <li>From age 50 once you reach 25 years’ service.</li>
          <li>
            Otherwise, if less than 25 years’ service, from the Compulsory
            Reitrement Age.
          </li>
        </ul>
        <p>The Compulsory Retirement Age is:</p>
        <ul>
          <li>
            60 years for Constables, Sergeants, Inspectors or Chief Inspectors.
          </li>
          <li>
            65 years for officers with any higher rank than Chief Inspector.
          </li>
        </ul>
        <p>
          As age retirements in the illustrator are capped at 60, if you are
          working at one of these high ranks but will not reach 25 years of
          service by 60, you will not be allowed to retire in the illustrator
          and an error message will appear.
        </p>
        <p>Please note:</p>
        <p>
          If you are a 1987 scheme member, you may be entitled to retire before
          age 55. If you are eligible and choose to retire before age 55, your
          2015 Scheme pension will be payable without reduction from State
          Pension Age (SPA).
          <b>
            However, the modeller does not display the pension being paid in
            instalments before and after age 55 for CARE and legacy benefits. As
            such, for the purposes of illustration in this modeller, if you
            choose to retire from the 1987 Scheme below age 55, 2015 scheme
            benefits are assumed to be deferred until age 55 and benefits are
            actuarially reduced (relative to deferred pension age SPA).
          </b>
        </p>
        <p>
          If you choose to retire from the 1987 Scheme at age 55 or later then
          you will be eligible to retire from the 2015 Scheme at the same time
          with actuarial reduction applied relative to age 60.
        </p>
        <p>
          If you are a 2006 scheme member, you can retire from age 55 or later.
          You will be eligible to retire from the 2015 Scheme at the same time.
          Where retirement age is below age 60, the amount of 2015 scheme
          pension shown includes actuarial adjustments for the earlier start of
          payments than the 2015 scheme normal retirement age of 60.
        </p>
        <p>
          The actuarial adjustments described above are those currently in
          effect. However, they will be reviewed periodically, and may increase
          or decrease. Your benefits at retirement will depend on actuarial
          adjustment factors in effect at that time.
        </p>
        <h3>2.5. Cash lump sum</h3>
        <p>
          At retirement you will be able to choose how much pension to commute
          for a lump sum (within limits) from each of the 1987 and 2015 schemes.
          This illustrator uses the commutation factors currently in effect.
          Please note that the 1987 commutation factors for Scotland are
          underpinned by the England & Wales commutation factors. As the
          commutation factors are higher for England & Wales currently, these
          are used for the calculations of the lump sum in the 1987 scheme.
          However, commutation factors are reviewed periodically and the amount
          of cash lump sum available at your actual retirement will depend on
          the commutation factors in effect at that time.
        </p>
        <p>
          At retirement from the 2006 scheme, you will get an automatic lump sum
          of four times your 2006 pension. You are eligible to exchange some of
          your lump sum for additional pension, but this is not modelled in the
          illustrator. This illustrator only shows your automatic lump sum.
        </p>
        <h3>2.6. Other pension benefits</h3>
        <p>
          The results do not include any money purchase Additional Voluntary
          Contributions (AVCs) benefits, added years or added pension which you
          may have purchased, pension debits or other special arrangements
          within the schemes.
        </p>
        <p>
          The illustrator does not show pension from other sources, for example
          the state pension or other private arrangements you may have.
        </p>
        <p>
          Your scheme provides survivor benefits payable in the event of your
          death, which are not shown here. See your scheme guide for details.
          Your benefit statement may also provide further information.
        </p>
        <p>
          No allowance has been made for guaranteed minimum pension (GMP) and
          any impact that GMP has on when benefits can be taken.
        </p>
        <h3>2.7. Part-time working and gaps in service</h3>
        <p>
          If you indicated you have worked part time or plan to do so in the
          future, the illustrator will allow for part-time hours worked.
        </p>
        <p>
          If you have a break in service less than 5 years, you can enter the
          relevant period and set the part-time proportion to 0%.
        </p>
        <p>
          Please do not use the tool if you have a gap in service of more than 5
          years. In this case, the illustrator will likely overestimate your
          benefits.
        </p>
        <h3>2.8. Transfers in</h3>
        <p>
          If you have a transfer value, you can enter details into the modeller
        </p>
        <p>
          For transfers before 1 April 2015: you can input the service
          transferred on the transfer date in years and days. This additional
          service will be added to your reckonable service for your final salary
          pension. For 1987 members, this will also be included in service
          calculations to check retirement eligibility and weighted accrual
          rate.
        </p>
        <p>
          For members after 1 April 2022: you can input their transferred in
          pension as at the date of transfer value. This will be revalued up to
          retirement by CPI + 1.25% per annum from scheme year transfer
          occurred.
        </p>

        <h3>2.9. Service cap</h3>
        <p>
          Members reaching the service cap (30 years in the 1987 scheme and 35
          years in the 2006 scheme) have been assumed to be allowed to join the
          2015 scheme from 1 April 2022. Members are able to opt out of the
          scheme on reaching 30 years of service (whilst remaining in
          employment), with entitlement to immediate payment of their pension on
          subsequent retirement.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation, aside from the
          unauthorised payment charge. Your benefits will be subject to the
          various tax rates and limits in force. You may wish to seek specialist
          advice if you think you could be affected.
        </p>
        <p>
          In certain circumstances, for some members, it is currently possible
          to take a maximum commuted lump sum from the 1987 scheme which exceeds
          that which HMRC allows to be paid tax free. In those circumstances if
          maximum commutation is taken the member will incur a tax liability.
          The maximum lump sum figures given in the results are not limited to
          what can be taken tax free. The results page will indicate where a tax
          liability arises and show the lump sum figures net of this
          unauthorised tax charge.
        </p>
      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>How to use this illustrator</h2>
        <p>
          It will not check your information against pension or other systems,
          so if you enter inaccurate information, the figures you get will be of
          limited value. No data you enter into this illustrator is stored or
          processed.
        </p>
        <p>
          By using this illustrator, you agree that you have read and understood
          the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This illustrator is for guidance and illustration only - it is not a
          benefit offer. It will give you a basic illustration of your pension
          benefits in the 2015 Section and your legacy scheme in two scenarios:
          choosing the legacy scheme and choosing the reformed scheme. Other
          sources of income and taxation, except for unauthorised payment
          charges, are not taken into account. If you have these, or other
          additional options, the figures you get may be less accurate, but
          still helpful. The outputs of the illustrator are not financial advice
          - if you’d like to speak to an independent financial adviser you can
          find one at <a href="https://www.unbiased.co.uk/">unbiased.co.uk</a>.
        </p>
        <p>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Future CPI Inflation</td>
              <td>2% per year</td>
            </tr>
          </tbody>
        </table>
        <p>
          Actual revaluation rates for Career Average Revalued Earnings (CARE)
          schemes are applied in the illustrator up to and including the latest
          available rate. Thereafter, future CARE revaluation rates are assumed
          to be in line with CPI + 1.25% at 3.25% each year.
        </p>
        <p>
          Users can set their own future salary increases between 0% and 6% per
          annum. If users do not enter a salary as at 1 April 2015 on the “Your
          Details” page, salaries between 2015 and current date are estimated
          using this assumption.
        </p>
        <p>
          The figures output by the illustrator are projected benefits and will
          not be the same as those in your Annual Benefit Statement.
        </p>
        <p>
          The projected benefits can be shown in real or nominal terms. If
          benefits are shown in <b>real</b> terms, this means the values are
          adjusted for inflation and represent what the pension would be worth
          today, so you can compare it with your current earnings and the amount
          you think you’ll need in retirement. Projected benefits in{" "}
          <b>nominal</b> terms show the benefits after allowing for assumed
          future inflation.
        </p>
        <p>
          The switch between real and nominal figures will be based on the
          assumed future inflation of 2% per annum.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>Welcome to the Remedy Benefits Illustrator</h2>
        <p>
          This illustrator is for guidance purposes only and the outputs
          provided are an estimate only. It is not intended to provide you with
          financial advice. If you require financial advice, you should contact
          an independent financial adviser.
        </p>
        <p>
          The Remedy gives you a choice, to be made at the point when you take
          your benefits. That choice is between your legacy scheme and the
          reformed scheme for the Remedy period (1 April 2015 to 31 March 2022).
          Both schemes offer highly valuable benefits.
        </p>
        <p>
          The illustrator is designed to provide members who are affected by the
          Remedy with an overview of how different choices may impact their
          benefits at retirement. It allows you to make an approximate
          comparison based on your inputs and certain assumptions about what
          happens in the future. It does not allow for all circumstances.
        </p>
      </Fragment>
    ),
  },
];
