import React, { Fragment, useContext, useState } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import InfoModal from '../../general/InfoModal'
import FullDateDropdown from "../../yourdetails/inputs/FullDateDropdown";
import PrettyTextInput from "../../yourdetails/inputs/PrettyTextInput";
import MultipleChoice from "../../yourdetails/inputs/MultipleChoice";
import classes from './InputReminder.module.css'
import { submitInputData } from "../../../shared/apis/mainAPI"
import PartTimeServiceInput from "../../yourdetails/inputs/PartTimeServiceInput2";
import { currencyFormatter } from "../../../utilities/Formatters";
import { poundToNumber } from "../../../utilities/Formatters";
import ServiceInput from "../../yourdetails/inputs/ServiceInput";
function InputReminder(props) {
    const { inputsRef, scrollPositions} = useContext(InputDataContext);
    const [show_modal, set_show_modal] = useState('')
    const [stored_value, set_stored_value] = useState('not_set')
    const [stored_service, set_stored_service] = useState([999, 999])
    const [error, setError] = useState('')
    const today = new Date()
    
    let earliestDoB = today.setDate(today.getDate() - 1);
    earliestDoB = new Date(earliestDoB).setFullYear(
    new Date(earliestDoB).getFullYear() - 68
    );
    const latestDoB = new Date(inputsRef.current.dateOfJoining).setFullYear(
        new Date(inputsRef.current.dateOfJoining).getFullYear() - 16
      )
    
    const earliestDJS = new Date(
        new Date(inputsRef.current.dateOfBirth).setFullYear(
          new Date(inputsRef.current.dateOfBirth).getFullYear() + 16
        )
      )

   
      function formatDate(inputDate) {
        // Parse the input date string
        const date = new Date(inputDate);
    
        // Helper function to get the ordinal suffix for the day
        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return 'th'; // for numbers like 11th, 12th, 13th, etc.
            switch (day % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        }
    
        // Extract the day, month, and year from the date
        const day = date.getDate();
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // 'short' gives the abbreviated month name
        const year = date.getFullYear();
    
        // Construct the formatted date string
        return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    }

    var mapper = {
        'dateOfBirth' : 'date',
        'dateOfJoining' : 'date', 
        'Service2022' : 'number',
        'salaryCurrent': 'number', 
        'salary2015' : 'number'
    }

    const names = {
        'dateOfBirth' : 'Date of birth', 
        'dateOfJoining' : 'Joining date',
        'status' : 'Status', 
        'Service2022' : 'Service', 
        'salaryCurrent' : 'Current salary', 
        'salary2015' : 'Salary in April 2015',
        'partTimeService' : 'Part time service'
    }

    const max_values = {
        'dateOfBirth' : latestDoB, 
        'dateOfJoining' : new Date("2012-03-31"), 
        'Service2022' : 55,
        'salaryCurrent' : 500000,
        'salary2015' : 500000
    }

    const min_values = {
        'dateOfBirth' : earliestDoB,
        'dateOfJoining': earliestDJS, 
        'Service2022' : 0,
        'salaryCurrent': 1,
        'salary2015' : 0

    }



    const pt_input = show_modal === 'partTimeService'

    const status_applicable = (new Date(inputsRef.current.dateOfJoining) < new Date("2008-04-01")) 

    if(status_applicable) {
        mapper['status'] = 'options'
    }

    const infoMessage = <Fragment>
        {!pt_input && <h1> Adjust the value for {names[show_modal]} </h1>}
        {mapper[show_modal] === 'date' &&
        <FullDateDropdown
        existing_value = {inputsRef.current[show_modal]}
        onChange = {
            
            (event) =>{
                var low_message
                if(show_modal === 'dateOfJoining') {
                    low_message = 'Given your date of birth, this joining date is too early'
                } else {
                    low_message = 'This date is too early'
                }
                set_stored_value(event.target.value)
                const val = new Date(event.target.value)
                if(val > max_values[show_modal]) {
                    setError('This date is too late.')
                } else if(val < min_values[show_modal]) {
                    setError(low_message)
                }  else {
                    setError('')
                }
            }
            }
        scrollPositions = {scrollPositions[show_modal]}
        min_year = {1960}
        max_year = {2022}
        go_red = {error !== ''}
        />
        }

        {mapper[show_modal] === 'options' &&
        <MultipleChoice
        options = {['Mental Health Officer', 'Special Class Officer', 'Neither of these']}
        changeHandler = {(event) =>{set_stored_value(event.target.value)}}
        chosen = {stored_value === 'not_set' ? inputsRef.current[show_modal] : stored_value}
        input = {show_modal}
        />
        }

        {mapper[show_modal] === 'number' && show_modal !== 'Service2022' &&
        <div style = {{'width' : '20%', 'marginLeft' : '40%'}}> 
        <PrettyTextInput
        type = {'currency'}
        left_margin = {'0px'}
        label = {names[show_modal]}
        value ={
            stored_value === 'not_set' ? 
            inputsRef.current[show_modal] : 
            stored_value
        }
        onChange = {
            (event) => {
                const val = poundToNumber(String(event.target.value))
                set_stored_value(val)
                if(val > max_values[show_modal]) {
                    setError('This value is too high.')
                } else if(val < min_values[show_modal]) {
                    setError('This value is too low.')
                } else if(val === '') {
                    setError('You must enter a value')
                } else {
                    setError('')
                }
            }
        }
        showError = {error !== ''}
        error = {error}
        />
        
        </div>
        }

        {
            show_modal === 'Service2022' && 
            <Fragment> 
            <div style = {{'width' : '20%', 'marginLeft' : '40%'}}> 
            <ServiceInput
            error = {error}
            onChangeYear = {
                (event) => {
                    const val = Number(event.target.value)
                    if(val > max_values[show_modal]) {
                        setError('This value is too high.')
                    } else if(val <= min_values[show_modal] && Number(stored_service[1]) === 0) {
                        setError('This value is too low.')
                    } else {
                        setError('')
                    }
                    set_stored_service(
                    [ val, stored_service[1]]
                )
                } 
            }
            onChangeDay = {
                (event) => {
                    var val = event.target.value
                   
                    val = Number(val)
                    val = Math.max(val, 0)
                    val = Math.min(val, 365)
                    if(Number(stored_service[0]) === 0 && val === 0) {
                        setError('This value is too low.')
                    }
                    if(error === 'This value is too low.' && val > 0) {
                        setError('')
                    }
                    
                    set_stored_service(
                        [stored_service[0], val]
                    ) 
                }
            }
            days = {stored_service[1] === 999 ? inputsRef.current.transferredServiceLegacyYears : stored_service[1]}
            years = {stored_service[0] === 999 ? inputsRef.current.transferredServiceLegacyYears : stored_service[0]}
            />
            </div>
            {inputsRef.current.BenefitStatement === 'Yes' && 
            <p
            className = {classes.service_pt_compatibility}
            > Please note: If the service you have provided is less than would be possible given your part-time
                working pattern, the results from this illustrator may not be valid.
            </p>
            }
            </Fragment>
        }

        {pt_input && <Fragment>
        <PartTimeServiceInput
            left_margin = {'0px'}
            visible = {true}
            min_year = {(new Date(inputsRef.current.dateOfJoining)).getFullYear()}
            max_year = {(new Date(inputsRef.current.dateOfBirth)).getFullYear() + Number(inputsRef.current.retirementAge) + 13}
        />
        {inputsRef.current.BenefitStatement === 'Yes' && 
        <p
        className = {classes.service_pt_compatibility}
        > Please note: If the service you have provided is less than would be possible given your part-time
            working pattern, the results from this illustrator may not be valid.
        </p>
        }
        </Fragment>
        }


        <p> {error}</p>
        {inputsRef.current.BenefitStatement === 'No' && show_modal === 'Service2022' &&
        <p> You indicated on the 'your details' page that you do not have a benefit statement telling you
            your service as at 2022. If you supply a value in the space above, we will assume that you do in fact have a benefit
            statement and the number provided has been taken from it.
        </p>
        }

        { ((new Date(inputsRef.current.dateOfJoining)) < (new Date('1990-05-17'))) &&
         stored_value ==='Special Class Officer' &&
         inputsRef.current.MaleNurse !== 'No'&&
        <p style = {{'color': 'red'}}
        > It appears that you are a Special Class Officer who joined before 17 May 1990.
            This means that if you are a male nurse, this illustrator will not be suitable for you.
        </p>
        }

    </Fragment>

//this function determines what happens when the modal closes.
//stored_value is the value the user selected while the modal was open.
//except in the case of the service input, where the value(s) they selected are in 
//stored_service
const modalHandler = () => {
    if(
        (((stored_value !== inputsRef.current[show_modal]) || pt_input) && error === '' && show_modal !== 'Service2022') ||
        (show_modal === 'Service2022' && error === '' && (
        stored_service[0] !== inputsRef.current.transferredServiceLegacyYears || stored_service[1] !== inputsRef.current.transferredServiceLegacyDays
        ))) {
        
        //if we change the date of joining, we may also need to update the legacySchemeName
        if(show_modal === 'dateOfJoining') {
            inputsRef.current.legacyScheme = (new Date(stored_value) < new Date("2008-04-01") ? '1995 Section' : '2008 Section')
        }


        const fetchData = async () => {
            if((!pt_input) & !(show_modal === 'Service2022')) {
            inputsRef.current[show_modal] = stored_value;
            }

            if(show_modal === 'Service2022')  {
                inputsRef.current.BenefitStatement = 'Yes'
                inputsRef.current['transferredServiceLegacyYears'] = stored_service[0]
                inputsRef.current['transferredServiceLegacyDays'] = stored_service[1]
            }

            try {
            const data = await submitInputData(
                pt_input ? inputsRef.current : {...inputsRef.current, [show_modal]: stored_value}
            );
            props.setOutputData(data);
            } catch (error) {
            console.error('Error fetching data:', error);
            // Handle any errors here
            }
        };
    
        // Call the async function
        fetchData();
        set_show_modal('')
    } else {
        set_show_modal('')
        setError('')
    }
}



    return(
        <div className = {classes.inputs_reminder} id ={'existing_inputs'}> 
        <h2> The illustrations above are based on the information entered in the Your Details section and are repeated below (click a button to adjust the value)</h2>
        <div className = {classes.button_container + ' ' + (status_applicable ? classes.with_status : classes.without_status)}> 
            {Object.keys(mapper).map(
                (key) => {
                    if(key !== 'Service2022') {
                        var val = inputsRef.current[key]
                    } else {
                        val = (inputsRef.current.transferredServiceLegacyYears === undefined ? '0' : 
                        String(inputsRef.current.transferredServiceLegacyYears)
                        ) + ' years; ' + (
                            Number(inputsRef.current.transferredServiceLegacyDays) > 0 ? 
                            String(inputsRef.current.transferredServiceLegacyDays) + ' days' : '0 days')
                    }
                    val = val === undefined ? 'Not available' : val
                    if(key === 'status' && status_applicable && val === 'Not available') {
                        val = 'Not specified yet'
                    }

                    if(key === 'salary2015' && val === 0) {
                        val = 'Not provided'
                    }
                        return(
                            <div key = {key}> 
                                <p> {names[key]}</p>
                                <button
                                onClick = {
                                    () => 
                                    {
                                        set_show_modal(key)
                                        if(key === 'Service2022') {
                                            set_stored_service(
                                                [inputsRef.current.transferredServiceLegacyYears, inputsRef.current.transferredServiceLegacyDays]
                                            )
                                        } else {
                                        set_stored_value(val)
                                        }
                                    }
                                }
                                > 
                                    {
                                    val === '' ?
                                    'NA' :
                                    (   mapper[key] === 'date' ? 
                                        formatDate(val) : 
                                        (['salary2015', 'salaryCurrent'].includes(key) ?
                                            (val !== 'Not provided' ? currencyFormatter(val) : val) :
                                            (
                                                (key === 'Service2022' && inputsRef.current.BenefitStatement === 'Yes') ? val :
                                                (key === 'Service2022' && inputsRef.current.BenefitStatement === 'No' ? 'Not available' :
                                                (val === 'Neither of these' ? 'Not MHO or SCO' : val))
                                                )
                                        )
                                    ) }
                                    
                                </button>
                            </div>
                        )

                    

                }
            )}
            <div>
                <p>Part-time service </p>
                <button
                onClick = {
                    () => 
                    {
                        set_show_modal('partTimeService')
                        set_stored_value('NA')
                    }
                }
                > {inputsRef.current.partTimeService.length === 0 ? 'None' : 'Click to view'} </button>
            
            </div>
        </div>
        {(show_modal !== '') && (
        <InfoModal 
        modalHandler={modalHandler} 
        infoMessage={infoMessage} 
        top = {show_modal === 'partTimeService' ? '5vh' : '15vh'}
        width = {show_modal === 'partTimeService' ? '90vw' : '60vw'}
        height = {show_modal === 'partTimeService' ? '90vh' : (show_modal === 'Service2022' ? '30vh' : '23vh')}
        />
      )}
      {inputsRef.current.status === undefined && status_applicable && 
            <button className = {classes.status_missing_warning}
            onClick = {
                () => 
                {
                    set_show_modal('status')
                }
            }
            > 
            If your joining date is before 1st April 2008, you need to 
            specify your status, otherwise the results you see may not be valid.
            Click here to select your status
            </button>
            }
        </div>
    )
}

export default InputReminder