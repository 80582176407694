import React, { useContext, useEffect, useState, useRef } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";
import FullDateDropdown from './FullDateDropdown';
import PrettyTextInput from "./PrettyTextInput";

const PartTimeServiceInput = (props) => {
  const scrollPositionsStart = useRef({})
  const scrollPositionsEnd = useRef({})
  const { inputsRef } = useContext(InputDataContext);
  const [partTimeService, setPartTimeService] = useState(
    inputsRef.current.partTimeService
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState(null);
  const [serviceGapWarning, setServiceGapWarning] = useState(null);
  const [ftePercentage, setFtePercentage] = useState("")

  const serviceGapWarningText = "If you have a gap in service that is greater than 5 years, \
  do not use this illustrator as it will overestimate your retirement benefits";

  const addService = () => {
    const newService = {
      From: startDate,
      To: endDate,
      ftePercentage: ftePercentage, 
    };
    console.log("new service ", newService);
    
    if (startDate >= endDate) {
      setError("Start date must be before end date.");
      return;
    }

    if (!ftePercentage) {
      setError("Please enter a percentage.");
      return;
    }

    const isOverlap = (service1, service2) => {
      const start1 = new Date(service1.From);
      const end1 = new Date(service1.To);
      const start2 = new Date(service2.From);
      const end2 = new Date(service2.To);

      return start1 <= end2 && start2 <= end1;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isOverlap(partTimeService[i], newService)) {
        setError("This entry overlaps with an existing part-time service.");
        return;
      }
    }

    const isChronalogical = (service1, service2) => {
      const start1 = new Date(service1.From);
      const start2 = new Date(service2.From);

      return start1 >= start2;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isChronalogical(partTimeService[i], newService)) {
        setError("Please enter services in chronological order.");
        return;
      }
    }

    const new_service = [
      ...partTimeService,
      { From: startDate, To: endDate, ftePercentage: ftePercentage },
    ]
    setPartTimeService(new_service);
    
    props.setInputs(
      (prev) => {
        return(
          {...prev, partTimeService : new_service}
        )
      }
    )

    setStartDate("");
    setEndDate("");
    setFtePercentage("");
    setError(null);
  };

  function parseDate(input) {
    const [year, month, day] = input.split('-');
    return new Date(year, month - 1, day); // JavaScript months are zero-indexed
}



  const removeService = (index) => {
    
    const new_service = partTimeService.filter((_, i) => i !== index)
    setPartTimeService(new_service);
    
    props.setInputs(
      (prev) => {
        return(
          {...prev, partTimeService : new_service}
        )
      }
    )

    setStartDate("");
    setEndDate("");
    setFtePercentage(""); 
    setError(null);
    
  };

  //currently showing this warning but letting them continue - otherwise can use setError for this instead
  useEffect(() => {
    const checkForLongServiceGap = () => {
      return partTimeService.some(service => {
        const startDate = new Date(service.From);
        const endDate = new Date(service.To);
        const lengthInMilliseconds = endDate - startDate;
        const lengthInYears = lengthInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        return parseInt(service.ftePercentage) === 0 && lengthInYears > 5;
      });
    };
  
    const needsServiceGapWarning = checkForLongServiceGap();
    setServiceGapWarning(needsServiceGapWarning ? serviceGapWarningText : null);
  }, [partTimeService]);

  return (
    <div className={classes.service_container + ' ' + (props.visible ? '' : classes.invisible)}>
      <h3>Tell us about your part-time working history</h3>
      
      
      <p>
        Please note that you do not need to enter any full-time periods of
        working if they fall in between separate periods of part-time working.
      </p>
      <p>
        If you have gaps in service, set the Percentage to 0%.
      </p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {serviceGapWarning && <p style={{ color: "red" }}>{serviceGapWarning}</p>}
      <div className = {classes.line} style = {{'marginTop' : '5%'}}> </div>
      <div className={classes.subContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.label_input}>
            <label>Start Date:</label>
            <FullDateDropdown
          existing_value = {startDate}
          onChange = {(e) => setStartDate(e.target.value)}
          scrollPositions = {scrollPositionsStart}
          min_year = {props.min_year}
          max_year = {props.max_year}
          min_month = {[2014, 2015].includes(props.min_year) ? 3 : 0} //corresponding to April if benefit statement available
          />
          </div>
          <div className={classes.label_input}>
            <label>End Date:</label>
            <FullDateDropdown
          existing_value = {endDate}
          onChange = {(e) => setEndDate(e.target.value)}
          scrollPositions = {scrollPositionsEnd}
          min_year = {props.min_year}
          max_year = {props.max_year}
          min_month = {3 }// corresponding to April
          />

          </div>
          <div style = {{'--box-width' : '80%', 'display' : 'flex', '--centralise' : 'flex'}}> 
            <PrettyTextInput
            left_margin = {props.left_margin}
            label = {"Percentage (%)"}
            value ={ftePercentage}
            onChange = {(e) => setFtePercentage(e.target.value)} 
            showError = {ftePercentage > 100}
            error = {"Percentage too high"}

            />
            <div className = {classes.button_container}> 
              <button onClick={addService}>Add Service</button>
            </div>
        </div>  
         
        </div>
        <div className = {classes.line} style = {{'marginBottom' : '3%'}}> </div>
        
      </div>
      <div className = {classes.table_container}>
        <p> Service that you add will appear in the table below.</p>
        <table>
          <thead>
            <tr>
              <th className={classes.leftColumn}></th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Percentage</th>
              <th className={classes.rightColumn}></th>
            </tr>
          </thead>
          <tbody>
            {partTimeService.map((service, i) => (
              <tr key={i}>
                <td className={classes.leftColumn}>{i + 1}.</td>
                <td>{new Date(service.From).toLocaleDateString("en-GB")}</td>
                <td>{new Date(service.To).toLocaleDateString("en-GB")}</td>
                <td>{service.ftePercentage}</td>  
                <td className={classes.rightColumn} style = {{'border' : 'none'}}>
                  <button onClick={() => removeService(i)}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {partTimeService.length > 0 && <p> Click 'X' to remove a row of part-time service history from the table</p>}
      </div>
    </div>
  );
};

export default PartTimeServiceInput;
