import React, { Fragment, useState, useRef, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { submitInputData } from "../../../shared/apis/mainAPI";

import classes from "./ControlSwitches.module.css";

const InflationSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [adjustInflation, setAdjustInflation] = useState(props.inputsRef.current.inflation);
  const initial_mount = useRef(true)
  const modalHandler = () => {
    setShowModal(!showModal);
  };



  useEffect(() => {
    if (!initial_mount.current) {

  
      // Define an async function inside useEffect
      const fetchData = async () => {
        props.inputsRef.current.inflation = adjustInflation;
        try {
          const data = await submitInputData(
            {...props.inputsRef.current, 'inflation': adjustInflation}
          );
          props.setOutputData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle any errors here
        }
      };
  
      // Call the async function
      fetchData();
    } else {
      initial_mount.current = false;
    }
  }, [adjustInflation]);

  const infoMessage = (
    <Fragment>
      <h2>Adjust for Inflation</h2>
      <p>
        You can choose to display your pension and lump sum in real or nominal
        terms. Nominal terms will show the value of lump sum and pension in
        terms of monetary amounts at the point of payment. Real terms is the
        nominal value adjusted for inflation and therefore will represent value
        in current money terms.
      </p>
      <p>
        "Real" corresponds to the "Allowing for inflation" option in the previous
        version of this illustrator. "Nominal" corresponds to the "In today's money terms"
        option.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container + (props.visibility ? '' : (' ' + classes.invisible_container)) + ' ' + classes.inflation} >
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Adjust for Inflation</h3>
        <button
          id="InflationSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioReal"
          name="radioInflation"
          value="real"
          onChange={() => setAdjustInflation("real")}
          defaultChecked
        />
        <label htmlFor="radioReal">Real</label>
        <input
          type="radio"
          id="radioNominal"
          name="radioInflation"
          value="real"
          onChange={() => setAdjustInflation("nominal")}
        />
        <label htmlFor="radioNominal">Nominal</label>
      </div>
    </div>
  );
};

export default InflationSwitch;
